import {DateTime, Duration} from "luxon";

export function parseTime(value?: string | number, zone?: string) {
  if (!value) {
    return DateTime.invalid("invalid value");
  }
  const num = Number(value);
  if (isFinite(num)) {
    return DateTime.fromMillis(num, {zone});
  } else {
    return DateTime.fromISO(value as string, {zone});
  }
}

export function parseDuration(value?: string | number | Duration): Duration {
  if (value === undefined || value === null) {
    return Duration.invalid("invalid value");
  }
  if (value instanceof Duration) {
    return value;
  }
  const num = Number(value);
  if (isFinite(num)) {
    return Duration.fromMillis(num);
  } else {
    return Duration.fromISOTime(value as string);
  }
}

export function parseDate(value?: string | number | Date | DateTime | null, zone?: string): DateTime {
  if (!value) {
    return DateTime.invalid("invalid value");
  }
  if (value instanceof DateTime) {
    return value;
  }
  if (value instanceof Date) {
    return DateTime.fromJSDate(value, {zone});
  }
  const num = Number(value);
  if (isFinite(num)) {
    return DateTime.fromMillis(num, {zone});
  } else {
    return DateTime.fromISO(value as string, {zone});
  }
}
