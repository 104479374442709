import useLogoutStore from "@admin/stores/useLogoutStore.ts";
import {AuthController} from "@admin/types/generated.ts";
import {replaceRoute} from "@core/composable/useAsyncCallback.ts";
import {RouteLocationNormalized, Router} from "vue-router";

export const logoutAction = async (router: Router, from: RouteLocationNormalized) => {
    const logout = useLogoutStore();
    await logout.execute(AuthController.logout());
    if (logout.error) {
        await replaceRoute(router, from);
    } else {
        window.location.assign('/');
    }
}