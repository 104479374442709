<template>
  <NDatePicker
    v-bind="$attrs"
    :placeholder="props.format"
    :format="props.format"
    :value-format="props.valueFormat"
    :time-picker-props="{use12Hours:false,format:timeFormat}"
    :date-format="props.dateFormat"
    :formatted-value="undefined"
    :value="value"
    type="daterange"
    @update-value="updated"
    style="width: 100%"
    @update-show="panelShow"
  >
    <template #date-icon>
      <slot name="date-icon"/>
    </template>
  </NDatePicker>
</template>
<script setup lang="ts">

import {parseDate} from "@core/utils/date.ts";
import {NDatePicker} from "naive-ui";
import {dateFormat, timeFormat, zone} from "@core/settings/dateTimeSettings.ts";
import {computed, nextTick} from "vue";

interface IProps {
  format?: string,
  valueFormat?: string,
  timeFormat?: string,
  dateFormat?: string,
  formattedValue?: [string, string],
  zone?: string,
}

const props = withDefaults(defineProps<IProps>(), {
  format: dateFormat,
  timeFormat: timeFormat,
  dateFormat: dateFormat,
  zone: zone,
  type: "date",
});
const emit = defineEmits(['update:value', 'update:formattedValue']);
const value = computed<[number, number] | null>(() => {
  if (!props.formattedValue) {
    return null;
  }
  const datetime1 = parseDate(props.formattedValue[0]);
  const datetime2 = parseDate(props.formattedValue[1]);
  return (datetime1.isValid && datetime2.isValid) ? [datetime1.toMillis(), datetime2.toMillis()] : null;
});

const updated = (value: [number, number]) => {
  let ret: [string | null, string | null] | null = null;
  if (value) {
    const _formattedValue1 = parseDate(value[0], 'utc').toISO();
    const _formattedValue2 = parseDate(value[1], 'utc').toISO();
    ret = [_formattedValue1, _formattedValue2];
  }
  emit('update:value', value, ret);
  emit('update:formattedValue', ret, value);
}

defineOptions({inheritAttrs: false});

async function panelShow(show: boolean) {
  if (show) {
    await nextTick();
    const nowText = document.getElementsByClassName('n-date-panel-actions__suffix')
      ?.item(0)
      ?.getElementsByClassName('n-button__content')
      ?.item(0);
    if (nowText?.textContent?.toLowerCase() === 'now') {
      nowText.textContent = 'Today';
    }
  }
}
</script>

<style scoped lang="scss">

</style>
